<template>
  <div v-if="!filterTokenActive || filterTokenActive == filterToken">
    <div
      id="offcanvasFilters"
      class="offcanvas offcanvas-end offcanvas-end--filters d-flex"
      :class="{ show: visible }"
      :style="{ visibility: visible ? 'visible' : 'hidden' }"
      tabindex="-1"
      aria-labelledby="offcanvasFiltersLabel"
    >
      <div class="offcanvas-header">
        <h3
          id="offcanvasFiltersLabel"
          class="offcanvas-title"
        >
          <span class="btn btn-icon btn-icon rounded-circle btn-flat-secondary bg-light-secondary me-2"><i data-feather="filter" /></span> Search & Filters
        </h3>
        <button
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          @click="closeOffcanva"
        />
      </div>
      <div class="offcanvas-body">
        <slot
          name="filters"
        />
      </div>
      <div class="offcanvas-footer mt-auto">
        <button
          v-if="!sending"
          type="button"
          class="btn btn-dark mb-1 d-grid w-100"
          @click="filter"
        >
          Apply
        </button>
        <button
          v-if="sending"
          class="btn btn-dark mb-1 w-100"
          type="button"
          disabled=""
        >
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
          <span class="ms-25 align-middle">Applying...</span>
        </button>
      </div>
    </div>
    <div
      v-if="visible"
      class="offcanvas-backdrop fade"
      :class="{ show: visible }"
      @click="closeOffcanva"
    />
  </div>
  <!-- -->
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  components: {},
  props: {
    filterPath: { type: String, required: true },
    filterToken: { type: String, required: false, default: null },
  },
  data() {
    return {
      sending: false,
    }
  },
  computed: {
    ...mapGetters({
      visible: 'modals/convoFilters',
      filterTokenActive: 'modals/filterToken',
      filters: 'filters/filters',
    }),
  },
  methods: {
    closeOffcanva() {
      this.$store.dispatch('modals/toggleConvoFilters', false)
      this.$store.dispatch('modals/toggleActiveFilterTab', null)
    },
    async filter() {
      this.sending = true
      this.$store.dispatch('pagination/clearPage')
      if (this.filterPath !== '-') {
        await this.$store.dispatch(this.filterPath, this.filters)
      }
      this.$emit('appliedFilters', true)
      this.sending = false
      this.closeOffcanva()
      feather.replace({
        width: 14,
        height: 14,
      })
    },
  },
}
</script>
